import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
/* Layouts */
import Layout1 from "../layouts/Layout1";
import AuthLayout1 from "../layouts/AuthLayouts/AuthLayout1";
/* Dashboards View */
import Dashboard2 from "../views/Dashboards/Dashboard2";
import HomePage from "../views/Dashboards/Homepage";
import Dashboard1 from "../views/Dashboards/Dashboard1";
import Dashboard3 from "../views/Dashboards/Dashboard3";
import Dashboard4 from "../views/Dashboards/Dashboard4";
import Dashboard5 from "../views/Dashboards/Dashboard5";
import AdminDash from "../views/Dashboards/AdminDashboard";
/* Authentic View */
import SignIn1 from "../views/AuthPages/Default/SignIn1";
import SignUp1 from "../views/AuthPages/Default/SignUp1";
import RecoverPassword1 from "../views/AuthPages/Default/RecoverPassword1";
import UpdatePassword1 from "../views/AuthPages/Default/UpdatePassword1";
import LockScreen1 from "../views/AuthPages/Default/LockScreen1";
import ConfirmMail1 from "../views/AuthPages/Default/ConfirmMail1";
/* Extra Pages */
import ErrorPage from "../views/Pages/ErrorPage";

/* ================================================================================= */
/*                                   GUIDES                                          */
/* ================================================================================= */
import GuidesList from "../views/Guides/ListCards";
import GuidesListAdmin from "../views/Guides/List";
import GuideImport from "../views/Guides/Import";
import GuideShow from "../views/Guides/Show";
import GuideEdit from "../views/Guides/Edit"; //_Edit for update chapters, criteria etc.
import GuideCreate from "../views/Guides/Create";
// import GuideShow from "../views/Guides/Show";

/* ================================================================================= */
/*                                   INSTITUTION                                     */
/* ================================================================================= */
import CreateInstitution from "../views/Institutions/Create";
import InstitutionList from "../views/Institutions/List";
import EditInstitution from "../views/Institutions/Edit";

/* ================================================================================= */
/*                                   USERS                                           */
/* ================================================================================= */
import UserList from "../views/User/UserList";
import CreateUser from "../views/User/Create";
import EditUser from "../views/User/Edit";

/* ================================================================================= */
/*                                   SERVICES                                        */
/* ================================================================================= */
import CreateService from "../views/Services/Create";
import EditService from "../views/Services/Edit";

/* ================================================================================= */
/*                                   FUNCTIONS                                        */
/* ================================================================================= */
import FunctionList from "../views/Fonctions/List";
import CreateFunction from "../views/Fonctions/Create";
import EditFunction from "../views/Fonctions/Edit";
/* ================================================================================= */
/*                                   SURVEYS                                         */
/* ================================================================================= */
import SurveyList from "../views/Surveys/List";
import CreateSurvey from "../views/Surveys/Create";
import EditSurvey from "../views/Surveys/Edit";
import StartSurvey from "../views/Surveys/Start";
/* ================================================================================= */
/*                                   ASSESSMENT                                         */
/* ================================================================================= */
import AssessmentList from "../views/Assessments/List";
import AssessmentStart from "../views/Assessments/Start";
import AssessmentResult from "../views/Assessments/Result";
// import AssessmentResultPdf from "../views/Assessments/ResultPdf";
/* ================================================================================= */
/*                                   POSTS                                         */
/* ================================================================================= */
import PostList from "../views/Posts/List";
import PostEdit from "../views/Posts/Edit";
import PostCreate from "../views/Posts/Create";
import PostShow from "../views/Posts/Show";
/* ================================================================================= */
/*                                   GRID                                         */
/* ================================================================================= */
import GridList from "../views/EvaluationGrid/List";
import GridCreate from "../views/EvaluationGrid/Create";
import GridEdit from "../views/EvaluationGrid/Edit";
// import GridShow from "../views/EvaluationGrid/Show";

import Greeting from "../views/AuthPages/Default/Greeting";

Vue.use(VueRouter);

// const homeRoutes = (prop, mode) => [
//   {
//     path: "",
//     name: "homepage",
//     meta: { auth: true },
//     component: HomePage
//   }
// ];

const childRoutes = (prop, mode) => [
  {
    path: "",
    name: "dashboard",
    meta: { auth: true },
    component: AdminDash,
  },
];

const GridRoutes = () => [
  {
    path: "",
    name: "grid-list",
    meta: { auth: true },
    component: GridList,
  },
  {
    path: "create",
    name: "grid-create",
    meta: { auth: true },
    component: GridCreate,
  },
  {
    path: "edit/:id",
    name: "grid-edit",
    meta: { auth: true },
    component: GridEdit,
  },
  // {
  //   path: "show/:id",
  //   name: "grid-show",
  //   meta: { auth: true },
  //   component: GridShow,
  // }
];

const GuideRoutes = () => [
  {
    path: "",
    name: "guide-list",
    meta: { auth: true },
    component: GuidesList,
  },
  {
    path: "admin",
    name: "guide-list-admin",
    meta: { auth: true },
    component: GuidesListAdmin,
  },
  // {
  //   path: "test/:id",
  //   name: "guide-list",
  //   meta: { auth: true },
  //   component: NewShow,
  // },
  {
    path: "show/:id",
    name: "guide-show",
    meta: { auth: true },
    component: GuideShow,
  },
  {
    path: "import",
    name: "guide-import",
    meta: { auth: true },
    component: GuideImport,
  },
  {
    path: "create",
    name: "guide-create",
    meta: { auth: true },
    component: GuideCreate,
  },
  {
    path: "edit/:id",
    name: "guide-edit",
    meta: { auth: true },
    component: GuideEdit,
  },
];

const institutionRoutes = () => [
  {
    path: "",
    component: InstitutionList,
    name: "list-institution",
    meta: { auth: true, roles: ["SUPER_ADMIN"] },
  },
  {
    path: "create",
    name: "create-institution",
    meta: { auth: true, roles: ["SUPER_ADMIN"] },
    component: CreateInstitution,
  },
  {
    path: "edit/:id",
    name: "edit-institution",
    meta: { auth: true, roles: ["SUPER_ADMIN"] },
    component: EditInstitution,
  },
  {
    path: "service/create",
    name: "create-service",
    meta: { auth: true, roles: ["SUPER_ADMIN"] },
    component: CreateService,
  },
  {
    path: "service/edit",
    name: "edit-service",
    meta: { auth: true, roles: ["SUPER_ADMIN"] },
    component: EditService,
  },
];

const userRoutes = () => [
  {
    path: "",
    component: UserList,
    name: "list-user",
    meta: { auth: true, roles: ["SUPER_ADMIN"] },
  },
  {
    path: "create",
    component: CreateUser,
    name: "create-user",
    meta: { auth: true, roles: ["SUPER_ADMIN"] },
  },
  {
    path: "edit/:id",
    component: EditUser,
    name: "edit-user",
    meta: { auth: true, roles: ["SUPER_ADMIN"] },
  },
];

const postRoutes = () => [
  {
    path: "",
    component: PostList,
    name: "list-post",
    meta: { auth: true },
  },
  {
    path: "create",
    component: PostCreate,
    name: "create-post",
    meta: { auth: true },
  },
  {
    path: "edit/:id",
    component: PostEdit,
    name: "edit-post",
    meta: { auth: true },
  },
  {
    path: ":id",
    component: PostShow,
    name: "show-post",
    meta: { auth: true },
  },
];

const functionRoutes = () => [
  {
    path: "",
    component: FunctionList,
    name: "list-function",
    meta: { auth: true },
  },
  {
    path: "create",
    component: CreateFunction,
    name: "create-function",
    meta: { auth: true },
  },
  {
    path: "edit/:id",
    component: EditFunction,
    name: "edit-function",
    meta: { auth: true },
  },
];

const surveyRoutes = () => [
  {
    path: "",
    component: SurveyList,
    name: "list-survey",
    meta: { auth: true },
  },
  {
    path: "create",
    component: CreateSurvey,
    name: "create-survey",
    meta: { auth: true },
  },
  {
    path: "edit/:id",
    component: EditSurvey,
    name: "edit-survey",
    meta: { auth: true },
  },
  {
    path: "start",
    component: StartSurvey,
    name: "start-survey",
    meta: { auth: true },
  },
];

const assessmentRoutes = () => [
  {
    path: "",
    component: AssessmentList,
    name: "list-assessment",
    meta: { auth: true },
  },
  {
    path: "start",
    component: AssessmentStart,
    name: "start-assessment",
    meta: { auth: true },
  },
  {
    path: "result/:id",
    component: AssessmentResult,
    name: "result-assessment",
    meta: { auth: true },
  },

  // {
  //   path: "create",
  //   component: CreateSurvey,
  //   name: "create-assessment",
  //   meta: { auth: true }
  // },
  // {
  //   path: "edit/:id",
  //   component: EditSurvey,
  //   name: "edit-assessment",
  //   meta: { auth: true }
  // }
];

const authChildRoutes = (prop, mode = false) => [
  {
    path: "login",
    name: "login",
    component: SignIn1,
  },
  {
    path: "password-reset",
    name: "password-reset",
    component: RecoverPassword1,
  },
  {
    path: "reset-password/:token",
    name: "reset-password-form",
    component: UpdatePassword1,
    // meta: {
    //   auth:false
    // }
  },
  // {
  //   path: "lock-screen1",
  //   name: prop + ".lock-screen1",
  //   component: LockScreen1
  // },
  // {
  //   path: "confirm-mail",
  //   name: "confirm-mail",
  //   component: ConfirmMail1
  // }
];

const routes = [
  {
    path: "/",
    meta: { auth: true },
    component: HomePage,
  },
  {
    path: "/",
    meta: { auth: true },
    component: HomePage,
  }, //Laisser cette entrée "/" en double, sinon la route Greetings ne s'affiche pas après le login

  // {
  //   path: "/dashboard",
  //   component: Layout1,
  //   children: childRoutes("dashboard")
  // },
  {
    path: "/greeting",
    name: "greeting",
    component: Greeting,
  },
  // {
  //   path: "/",
  //   redirect: "/dashboard",
  //   component: Layout1,
  //   children: childRoutes("dashboard")
  // },
  // {
  //   path: "/assessment/result/:id/pdf",
  //   component: AssessmentResultPdf,
  //   name: "result-assessment-pdf",
  //   meta: { auth: true },
  // },
  {
    path: "/guide",
    component: Layout1,
    children: GuideRoutes(),
  },
  {
    path: "/grid",
    component: Layout1,
    children: GridRoutes(),
  },
  {
    path: "/institution",
    component: Layout1,
    children: institutionRoutes(),
  },
  {
    path: "/assessment",
    component: Layout1,
    children: assessmentRoutes(),
  },
  {
    path: "/user",
    component: Layout1,
    children: userRoutes(),
  },
  {
    path: "/function",
    component: Layout1,
    children: functionRoutes(),
  },
  {
    path: "/post",
    component: Layout1,
    children: postRoutes(),
  },
  {
    path: "/survey",
    component: Layout1,
    children: surveyRoutes(),
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: AuthLayout1,
    children: authChildRoutes("auth1"),
  },
  { path: "*", component: ErrorPage },
];

routes.map(function (route, i) {
  if (i) {
    route.path = "/:lang" + route.path;
  }
});

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    const promise = store.dispatch("Auth/getSession");
    promise
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: "greeting" });
      });
  } else {
    next();
  }
});

export default router;
