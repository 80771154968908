<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">{{ tableTitle }}</h4>
          </template>
          <template v-slot:headerAction>
            <div class="row" style="padding: 10px">
              <b-dropdown
                class="col mt-2"
                style="height: 35px"
                variant="primary"
                menu-class="p-2"
              >
                <template #button-content
                  ><i
                    class="fas fa-filter"
                    style="color: white; font-size: 14px"
                  />
                  {{ $t("buttons.filter") }}
                </template>
                <table class="">
                  <tr>
                    <td class="p-2">Etablissement</td>
                    <td>
                      <select
                        style="width: 150px"
                        class="form-control-sm pull-right"
                        v-model="selectedInst"
                      >
                        <option value="all">Tout</option>
                        <option
                          v-for="inst of institutions"
                          :key="inst.id"
                          :value="inst.id"
                        >
                          {{ inst.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-2">Service</td>
                    <td>
                      <select
                        style="width: 150px"
                        class="form-control-sm pull-right"
                        v-model="selectedServ"
                      >
                        <option value="all">Tout</option>
                        <option
                          v-for="serv of services"
                          :key="serv.id"
                          :value="serv.id"
                        >
                          {{ serv.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </table>
                <button
                  @click="
                    filteredList = null;
                    selectedInst = 'all';
                    selectedLevel = 'all';
                    selectedServ = 'all';
                  "
                  class="btn btn-danger btn-sm pull-right mt-1"
                >
                  Reset
                </button>
              </b-dropdown>
              <b-form-input
                id="filter-input"
                class="col m-2"
                style="width: 300px; height: 35px"
                v-model="filter"
                type="search"
                :placeholder="$t('inputs.search')"
              ></b-form-input>
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table
                  bordered
                  :filter="filter"
                  @filtered="onFiltered"
                  hover
                  :per-page="20"
                  :current-page="currentPage"
                  :items="filteredList ? filteredList : rows"
                  id="my-table"
                  :fields="cols"
                  foot-clone
                >
                  <template v-slot:cell(survey.title)="data">
                    <span :class="'surv-id:' + data.item.survey.id">{{
                      data.item.survey.title
                    }}</span>
                  </template>
                  <template v-slot:cell(guides)="data">
                    <li
                      :class="'guid-id:' + data.item.survey.id"
                      v-for="guid of data.item.survey.guides"
                      :key="guid.id"
                    >
                      {{ guid.title }}
                    </li>
                  </template>
                  <template v-slot:cell(services)="data">
                    <li
                      :class="'serv-id:' + data.item.survey.id"
                      v-for="ser of data.item.survey.services"
                      :key="ser.id"
                    >
                      {{ ser.name }}
                    </li>
                  </template>
                  <template v-slot:cell(survey.institution.name)="data">
                    <span :class="'est-id:' + data.item.survey.id">{{
                      data.item.survey.institution.name
                    }}</span>
                  </template>
                  <template v-slot:cell(progress)="data">
                    <div class="text-center">
                      <span> {{ data.item.progression_percent }} %</span>
                    </div>
                    <b-progress height="1.5rem">
                      <b-progress-bar
                        :value="data.item.progression_percent"
                        variant="success"
                      >
                      </b-progress-bar
                    ></b-progress>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      v-if="data.item.user.id == user.id"
                      variant=" iq-bg-danger mr-1 mt-1"
                      :title="$t('buttons.myResults')"
                      size="sm"
                      :to="
                        '/' +
                        $i18n.locale +
                        `/assessment/result/${data.item.id}`
                      "
                      ><i class="ri-bar-chart-fill m-0"></i>
                      {{ $t("buttons.myResults") }}</b-button
                    >
                    <b-button
                      v-else
                      variant=" iq-bg-primary mr-1 mt-1"
                      :title="$t('buttons.results')"
                      size="sm"
                      :to="
                        '/' +
                        $i18n.locale +
                        `/assessment/result/${data.item.id}`
                      "
                      ><i class="ri-bar-chart-fill m-0"></i>
                      {{ $t("buttons.results") }}
                    </b-button>
                    <!-- <b-button
                      variant=" iq-bg-primary mr-1 mt-1"
                      size="sm"
                      title="Demarrer le questionnaire"
                      v-if="data.item.user.id == user.id"
                      :to="'/'+$i18n.locale +
                        `/assessment/start?survey_id=${data.item.survey.id}&assessment_id=${data.item.id}`
                      "
                      ><i class="ri-play-line m-0"></i
                    ></b-button> -->
                  </template>
                </b-table>
                <b-pagination
                  class="mt-3 pull-right"
                  v-model="currentPage"
                  :total-rows="rows.length"
                  :per-page="20"
                  aria-controls="my-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import { xray } from "../../config/pluginInit";
import ApiService from "../../Utils/axios";

export default {
  name: "UiDataTable",
  props: {
    addBtn: Object,
    tableTitle: String,
    rows: Array,
    cols: Array,
    fetchList: Function,
  },
  mounted() {
    xray.index();

    let currentId;
    let cellsGroups = {};
    let cellsGroups1 = {};
    let cellsGroups2 = {};
    let cellsGroups3 = {};
    this.rows.forEach((e) => {
      if (currentId == e.survey.id) return;
      currentId = e.survey.id;
      if (!cellsGroups[currentId]) cellsGroups[currentId] = [];
      if (!cellsGroups1[currentId]) cellsGroups1[currentId] = [];
      if (!cellsGroups2[currentId]) cellsGroups2[currentId] = [];
      if (!cellsGroups3[currentId]) cellsGroups3[currentId] = [];

      const cells = document.getElementsByClassName("surv-id:" + e.survey.id);
      const cells1 = document.getElementsByClassName("guid-id:" + e.survey.id);
      const cells2 = document.getElementsByClassName("est-id:" + e.survey.id);
      const cells3 = document.getElementsByClassName("serv-id:" + e.survey.id);

      for (let i = 0; i < cells.length; i++) {
        cellsGroups[currentId].push(cells.item(i));
        cellsGroups1[currentId].push(cells1.item(i));
        cellsGroups2[currentId].push(cells2.item(i));
        cellsGroups3[currentId].push(cells3.item(i));
      }
    });

    Object.keys(cellsGroups).forEach((e) => {
      const cells = cellsGroups[e];
      const cells1 = cellsGroups1[e];
      const cells2 = cellsGroups2[e];
      const cells3 = cellsGroups3[e];
      if (cells.length <= 1) return;
      cells.forEach((cell, i) => {
        if (i > 0) {
          cell.style.display = "none";
          cells1[i].style.display = "none";
          cells2[i].style.display = "none";
          cells3[i].style.display = "none";
        }

        cell = cell.parentElement;
        let cell1 = cells1[i].parentElement;
        let cell2 = cells2[i].parentElement;
        let cell3 = cells3[i].parentElement;
        if (i == 0) {
          cell.style.borderBottom = "none";
          cell1.style.borderBottom = "none";
          cell2.style.borderBottom = "none";
          cell3.style.borderBottom = "none";
        } else if (i == cells.length - 1) {
          cell.style.borderTop = "none";
          cell1.style.borderTop = "none";
          cell2.style.borderTop = "none";
          cell3.style.borderTop = "none";
        } else {
          cell.style.borderTop = "none";
          cell1.style.borderTop = "none";
          cell2.style.borderTop = "none";
          cell3.style.borderTop = "none";
          cell.style.borderBottom = "none";
          cell1.style.borderBottom = "none";
          cell2.style.borderBottom = "none";
          cell3.style.borderBottom = "none";
        }
      });
    });
  },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  watch: {
    selectedInst() {
      this.handleFilter();
    },
    selectedServ() {
      this.handleFilter();
    },
    selectedLevel() {
      this.handleFilter();
    },
  },
  beforeMount() {
    this.fetchInstitutions();
    this.fetchServices();
  },
  methods: {
    default() {
      return {
        id: this.rows.length,
      };
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchInstitutions() {
      new ApiService()
        .get("/api/institution")
        .then((res) => {
          this.institutions = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchServices() {
      new ApiService()
        .get("/api/service")
        .then((res) => {
          if (this.selectedInst != "all") {
            this.services = res.data.data.filter(
              (e) => e.institution.id == this.selectedInst
            );
          } else {
            this.services = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleFilter() {
      let list = this.rows;

      if (this.selectedServ != "all") {
        let res = [];
        list.forEach((e) => {
          e.survey.services.forEach((v) => {
            if (v.id == this.selectedServ) {
              res.push(e);
              return;
            }
          });
        });
        list = res;
      }

      if (this.selectedInst != "all") {
        this.fetchServices();
        list = list.filter((e) => e.survey.institution.id == this.selectedInst);
      }
      this.filteredList = list;
    },
    edit(id) {
      this.$router.push("/" + this.$i18n.locale + "/survey/edit/" + id);
    },
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.title;
      this.$bvModal.show("modal-1");
    },
    remove(item) {
      let api = new ApiService();
      api
        .delete("/api/survey/" + item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Questionnaire supprimé avec succée",
            type: "success",
          });
        })
        .catch((err) => {
          if (err.response.data.code && err.response.data.code === "23000") {
            this.$store.dispatch("Alert/activate", {
              text: "Le questionnaire ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
              type: "danger",
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
  },
  data() {
    return {
      currentPage: 1,
      filter: "",
      modalMsg: "",
      item: null,
      institutions: [],
      services: [],
      filteredList: null,
      selectedInst: "all",
      selectedServ: "all",
      selectedLevel: "all",
    };
  },
};
</script>

<style>
.star {
  width: 18px;
  color: #eeeeee;
}

.laurel {
  width: 27px;
}
</style>
